





















































.my_layout {
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  position: absolute;
}
.all_content {
  display: flex;
  flex-direction: column;
  flex: 2;
  position: relative;
  margin: 16px auto;
  width: 90%;
}
