














































.herader {
  height: 70px;
  line-height: 70px;
  width: 100%;
  color: white;
  padding-left: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user_box {
    display: flex;
    align-items: center;
    .user_img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .user_name,
    .logo_out {
      line-height: 1;
      font-size: 20px;
    }
    .logo_out {
      margin-left: 20px;
      font-size: 0.9rem;
    }
  }
}
